import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(3, 0),
  },
  list: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
}));

export { useStyles };
