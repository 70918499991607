import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@utilitywarehouse/colour-system';

const useStyles = makeStyles(({ spacing, typography }) => ({
  container: {
    marginBottom: spacing(3),
  },
  link: {
    fontWeight: typography.fontWeightMedium,
    color: colors.cyan600,
  },
}));

export { useStyles };
