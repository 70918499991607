import React from 'react';
import { Typography } from '@material-ui/core';
import { bool } from 'prop-types';

import { GENERAL_TERMS_URL } from 'app/config/externalLinks';

import { Link } from '../Link';
import { useStyles } from './styles';

const CoolingOffSection = ({ hasMultipleServices }) => {
  const classes = useStyles();
  const coolOffPeriod = hasMultipleServices ? '30' : '14';

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.label} variant="body1">
        Cooling off period
      </Typography>
      <Typography variant="inherit" component="p">
        You can cancel for any reason within {coolOffPeriod} days of submitting
        your order. See our <Link href={GENERAL_TERMS_URL}>terms</Link> for more
        details.
      </Typography>
    </div>
  );
};

CoolingOffSection.propTypes = {
  hasMultipleServices: bool.isRequired,
};

export { CoolingOffSection };
