import React, { useEffect } from 'react';
import {
  bool,
  func,
  string,
  shape,
  object,
  objectOf,
  oneOfType,
} from 'prop-types';
import { Paper, Typography } from '@material-ui/core';

import { DirectDebitForm } from './DirectDebitForm';
import { BillingAddress } from './BillingAddressSection';
import { LegalSection } from './LegalSection';
import { useStyles } from './styles';

const DirectDebit = ({
  accountHolderName = '',
  errors,
  onFormChange,
  pristine,
  shouldCreditCheck,
  supplyAddress,
  updateName,
  values,
}) => {
  const classes = useStyles();

  // Load customer name from about you section
  useEffect(() => {
    updateName(accountHolderName);
  }, [accountHolderName, updateName]);

  return (
    <Paper classes={{ root: classes.wrapper }}>
      <Typography className={classes.heading} variant="h3" color="primary">
        Your direct debit
      </Typography>
      <DirectDebitForm
        errors={errors}
        onFormChange={onFormChange}
        pristine={pristine}
        values={values}
      />
      <BillingAddress
        errors={errors}
        onFormChange={onFormChange}
        pristine={pristine}
        values={values}
        supplyAddress={supplyAddress}
      />
      <LegalSection showCreditCheck={shouldCreditCheck} />
    </Paper>
  );
};

DirectDebit.propTypes = {
  accountHolderName: string,
  errors: objectOf(
    shape({
      valid: bool.isRequired,
      reason: string,
      field: string.isRequired,
    })
  ),
  onFormChange: func.isRequired,
  pristine: objectOf(bool),
  shouldCreditCheck: bool.isRequired,
  supplyAddress: object.isRequired,
  updateName: func.isRequired,
  values: objectOf(oneOfType([string, bool])).isRequired,
};

export default DirectDebit;
