import { makeStyles } from '@material-ui/core';
import { colors } from '@utilitywarehouse/colour-system';

import {
  PRODUCT_BROADBAND,
  PRODUCT_ENERGY,
  PRODUCT_INSURANCE,
  PRODUCT_MOBILE,
} from 'app/redux/modules/Bundles/constants';
import {
  ADD_ON_BILL_PROTECTOR,
  ADD_ON_CASHBACK_CARD,
} from 'app/redux/modules/OrderSummary/constants';

const ICON_SIZE_PX = 16;
const ICON_WRAPPER_SIZE_PX = 24;

const iconColorMap = new Map([
  [PRODUCT_ENERGY, colors.apple800],
  [PRODUCT_BROADBAND, colors.pink700],
  [PRODUCT_MOBILE, colors.gold500],
  [PRODUCT_INSURANCE, colors.rose700],
  [ADD_ON_CASHBACK_CARD, colors.grape500],
  [ADD_ON_BILL_PROTECTOR, colors.grape500],
]);

const iconWrapperColorMap = new Map([
  [PRODUCT_ENERGY, colors.apple50],
  [PRODUCT_BROADBAND, colors.pink50],
  [PRODUCT_MOBILE, colors.gold50],
  [PRODUCT_INSURANCE, colors.rose50],
  [ADD_ON_CASHBACK_CARD, colors.grape50],
  [ADD_ON_BILL_PROTECTOR, colors.grape50],
]);

const useStyles = (serviceId) =>
  makeStyles((theme) => {
    const iconColor = iconColorMap.get(serviceId) ?? 'inherit';
    const wrapperColor = iconWrapperColorMap.get(serviceId) ?? 'inherit';

    return {
      wrapper: {
        width: ICON_WRAPPER_SIZE_PX,
        height: ICON_WRAPPER_SIZE_PX,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        backgroundColor: wrapperColor,
      },
      icon: {
        width: ICON_SIZE_PX,
        height: ICON_SIZE_PX,
        color: iconColor,
      },
    };
  });

export { useStyles };
