import { shape, string, arrayOf, oneOfType, node, boolean } from 'prop-types';

import { price } from 'app/constants/propTypes';

const basketItemProps = {
  id: string.isRequired,
  items: arrayOf(string),
  priceValue: string.isRequired,
  hasDiscount: boolean,
  oneTimeCost: shape({
    text: oneOfType([string, node]).isRequired,
    value: string,
    subtext: string,
  }),
  quote: shape(),
};

const basketProps = {
  addOns: arrayOf(shape(basketItemProps)),
  discountedTotalPrice: price,
  services: arrayOf(shape(basketItemProps)).isRequired,
  totalPrice: price.isRequired,
  discounts: arrayOf(shape({ label: string, value: string })),
  totalText: oneOfType([string, node]),
};

export { basketItemProps, basketProps };
