import React, { useState, useRef } from 'react';
import { node, bool } from 'prop-types';
import { Typography } from '@material-ui/core';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';

import { EVENTS } from 'app/lib/analytics/constants';
import useScrollIntoView from 'app/lib/analytics/useScrollIntoView';
import ContentDropdown from 'modules/Shared/ContentDropdown';
import { useStyles } from './styles';

const GuaranteeListItem = ({ children }) => {
  const classes = useStyles();
  return (
    <li className={classes.debitGuaranteeListItem}>
      <FlagPointIcon className={classes.debitGuaranteeListIcon} />
      <Typography>{children}</Typography>
    </li>
  );
};

const useCreditCheckTrackEvents = (creditCheckRef) => {
  const creditCheckDisplayed = useTrackOnce();

  useScrollIntoView(
    creditCheckRef,
    ({ visible }) => {
      if (visible) creditCheckDisplayed(EVENTS.CREDIT_CHECK_DISPLAYED);
    },
    { threshold: 0.8 }
  );
  return { creditCheckRef };
};

const LegalSection = ({ showCreditCheck }) => {
  const classes = useStyles();
  const creditCheckRef = useRef();
  const [guaranteeOpen, setGuaranteeOpen] = useState(false);

  useCreditCheckTrackEvents(creditCheckRef);

  return (
    <div className={classes.container}>
      {showCreditCheck && (
        <Typography ref={creditCheckRef} variant="inherit" component="p">
          Like most utility companies, we`ll run a credit check to work out if
          you`ll need to pay us a deposit. This will leave a “footprint” on your
          credit file (see our Privacy Policy). By submitting your order, you
          agree to this check being run.
        </Typography>
      )}
      <Typography variant="inherit" component="p">
        {showCreditCheck ? 'You also' : 'By submitting your order, you'} agree
        that you are authorised to set up a Direct Debit for this account.
      </Typography>
      <ContentDropdown
        fullWidth
        label={
          <Typography
            className={classes.guaranteeLabel}
            variant="h5"
            component="h3"
          >
            Direct Debit Guarantee
          </Typography>
        }
        open={guaranteeOpen}
        onOpen={() => setGuaranteeOpen(!guaranteeOpen)}
        classes={{ root: classes.contentDropdownButton }}
      >
        <ul className={classes.debitGuaranteeList}>
          <GuaranteeListItem>
            This Guarantee is offered by all banks and building societies that
            accept instructions to pay Direct Debits.
          </GuaranteeListItem>
          <GuaranteeListItem>
            If there are any changes to the amount, date or frequency of your
            Direct Debit the Utility Warehouse will notify you 3 working days in
            advance of your account being debited or as otherwise agreed. If you
            request the Utility Warehouse to collect a payment, confirmation of
            the amount and date will be given to you at the time of the request.
          </GuaranteeListItem>
          <GuaranteeListItem>
            If an error is made in the payment of your Direct Debit, by the
            Utility Warehouse or your bank or building society, you are entitled
            to a full and immediate refund of the amount paid from your bank or
            building society.
          </GuaranteeListItem>
          <GuaranteeListItem>
            If you receive a refund you are not entitled to, you must pay it
            back when the Utility Warehouse asks you to.
          </GuaranteeListItem>
          <GuaranteeListItem>
            You can cancel a Direct Debit at any time by simply contacting your
            bank or building society. Written confirmation may be required.
            Please also notify us.
          </GuaranteeListItem>
        </ul>
      </ContentDropdown>
    </div>
  );
};

GuaranteeListItem.propTypes = {
  children: node.isRequired,
};

LegalSection.propTypes = {
  showCreditCheck: bool.isRequired,
};

export { LegalSection };
