import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import { convertStringToBool } from 'app/redux/utils/convertStringToBool';
import RadioButtonGroup from 'app/components/modules/Shared/MultiSelection/RadioButtonGroup';
import TextField from 'modules/CustomerUI/TextField';
import { ABOUT_YOU_TITLES } from 'app/redux/modules/YourDetails/constants';
import { aboutYouSection } from 'app/redux/modules/YourDetails/form';

import { YES_NO_OPTIONS } from '../../constants';
import { DobPicker } from '../DobPicker';
import useStyles from '../styles';

const {
  ABOUT_YOU_ADDITIONAL_HOLDER_SELECT,
  ABOUT_YOU_ADDITIONAL_TITLE,
  ABOUT_YOU_ADDITIONAL_NAME_FIRST,
  ABOUT_YOU_ADDITIONAL_NAME_LAST,
  ABOUT_YOU_ADDITIONAL_DATE_OF_BIRTH,
} = aboutYouSection;

const AddtionalAccountHolderSection = ({
  updateForm,
  getFieldProps,
  fields,
}) => {
  const classes = useStyles();
  return (
    <>
      <RadioButtonGroup
        radioGroupClasses={{
          container: classes.formAdditionalSelect,
          radioGroup: classes.formAdditionalSelectItems,
          radioButton: classes.formAdditionalSelectItem,
        }}
        label="Would you like to add a joint account holder?"
        options={YES_NO_OPTIONS}
        onChange={(_, value) =>
          updateForm(
            ABOUT_YOU_ADDITIONAL_HOLDER_SELECT,
            convertStringToBool(value)
          )
        }
        value={fields[ABOUT_YOU_ADDITIONAL_HOLDER_SELECT]}
        name={ABOUT_YOU_ADDITIONAL_HOLDER_SELECT}
      />

      {fields[ABOUT_YOU_ADDITIONAL_HOLDER_SELECT] && (
        <>
          <TextField
            label="Title"
            select
            className={classes.textInput}
            {...getFieldProps(ABOUT_YOU_ADDITIONAL_TITLE)}
          >
            {ABOUT_YOU_TITLES.map((title) => (
              <MenuItem value={title} key={title} data-test-id={title}>
                {title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textInput}
            label="First name"
            {...getFieldProps(ABOUT_YOU_ADDITIONAL_NAME_FIRST)}
          />
          <TextField
            className={classes.textInput}
            label="Last name"
            {...getFieldProps(ABOUT_YOU_ADDITIONAL_NAME_LAST)}
          />
          <DobPicker
            className={classes.textInput}
            {...getFieldProps(ABOUT_YOU_ADDITIONAL_DATE_OF_BIRTH)}
          />
        </>
      )}
    </>
  );
};

AddtionalAccountHolderSection.propTypes = {
  updateForm: PropTypes.func.isRequired,
  getFieldProps: PropTypes.func.isRequired,
  fields: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
};

export { AddtionalAccountHolderSection };
