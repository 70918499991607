import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Link, Button, Typography, Box } from '@material-ui/core';
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import { createTheme } from '@utilitywarehouse/partner-ui-mui-theme';

import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';

import {
  HELP_URL,
  CONTACT_URL,
  GENERAL_TERMS_URL,
  PRIVACY_URL,
  COOKIES_URL,
} from 'config/externalLinks';
import { CONFIG_BASE_URL } from 'config';
import styles from './styles';

const darkTheme = createMuiTheme(createTheme('dark'));
const HelpSection = ({
  cashBack,
  remoteSession,
  setStartAgainDialog,
  applicationId,
}) => {
  const classes = makeStyles(styles)();
  const { pathname, search } = useLocation();

  const tracking = useTracking();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.leftColumn}>
          {!remoteSession && (
            <Button
              className={classes.startAgain}
              variant="text"
              classes={{ label: classes.startAgainLabel }}
              onClick={() => {
                setStartAgainDialog(true);
              }}
            >
              Start again
            </Button>
          )}
          <div className={classes.linksWrapper}>
            <div className={classes.linksColumn}>
              <Typography variant="h6" className={classes.linksColumnTitle}>
                Need any help?
              </Typography>
              <Link classes={{ root: classes.link }} href={HELP_URL}>
                Help and Support
              </Link>
              <Link classes={{ root: classes.link }} href={CONTACT_URL}>
                Get in touch
              </Link>
            </div>
            <div className={classes.linksColumn}>
              <Typography variant="h6" className={classes.linksColumnTitle}>
                Legals
              </Typography>
              <Link classes={{ root: classes.link }} href={GENERAL_TERMS_URL}>
                Terms &amp; Conditions
              </Link>
              <Link classes={{ root: classes.link }} href={PRIVACY_URL}>
                Privacy
              </Link>
              <Link
                classes={{ root: classes.link }}
                href={COOKIES_URL}
                id="ot-sdk-btn"
                className="ot-sdk-show-settings"
                onClick={(e) => {
                  if (window.OneTrust) {
                    e.preventDefault();
                    e.stopPropagation();
                    window.OneTrust.ToggleInfoDisplay();
                  }
                }}
              >
                Cookies
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <div className={classes.buttons}>
            {cashBack && (
              <Button
                className={classes.button}
                component={Link}
                href={`${CONFIG_BASE_URL}${pathname.replace(
                  /^\//,
                  ''
                )}${search}#cbc`}
                variant="outlined"
                classes={{ root: classes.cbcButton }}
                onClick={() => {
                  tracking.track(EVENTS.CBC_CASHBACK_CHALLENGE_CLICKED);
                }}
              >
                Cashback Challenge
              </Button>
            )}
          </div>
        </div>
      </div>
      {applicationId && (
        <Box ml={1}>
          <Typography variant="body2">
            Application ID:{' '}
            <span className={classes.appId}>{applicationId}</span>
          </Typography>
        </Box>
      )}
    </>
  );
};

HelpSection.propTypes = {
  cashBack: PropTypes.bool,
  remoteSession: PropTypes.bool,
  setStartAgainDialog: PropTypes.func.isRequired,
  applicationId: PropTypes.string,
};

const ThemedHelpSection = (props) => (
  <MuiThemeProvider theme={darkTheme}>
    <HelpSection {...props} />
  </MuiThemeProvider>
);

export default ThemedHelpSection;
