import makeStyles from '@material-ui/core/styles/makeStyles';

const style = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1, 0),
    textAlign: 'center',
  },
  iconContianer: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  icon: {
    color: theme.palette.error.main,
    height: 50,
    width: 50,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  closeIcon: {
    width: 32,
    height: 32,
  },
});

export default makeStyles(style);
