import { useState } from 'react';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';

import { EVENTS } from 'app/lib/analytics/constants';
import { convertStringToBool } from 'app/redux/utils/convertStringToBool';

const muiDialogCloseEvents = ['backdropClick', 'escapeKeyDown'];

const useMarketingModalForm = (onOptChange) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const analytics = useTracking();

  const handleModalOpen = () => {
    setIsModalOpen(true);
    analytics.track(EVENTS.JTC_MARKETING_PREFERENCES_DIALOG_OPENED);
  };

  const handleModalClose = (_, reason) => {
    if (!muiDialogCloseEvents.includes(reason)) {
      setIsModalOpen(false);
    }
  };

  const handleOptCheck = (_, value) => onOptChange(convertStringToBool(value));

  return { isModalOpen, handleModalOpen, handleModalClose, handleOptCheck };
};

export { useMarketingModalForm };
