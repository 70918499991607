import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Footer from 'modules/layout/Footer';
import { withStyles } from '@material-ui/core/styles';

const useStyles = () => ({
  '@global': {
    html: {
      scrollPaddingBottom: '75px',
    },
  },
});

class PageTemplate extends Component {
  static defaultProps = {
    helmetTitle: 'Utility Warehouse - Join The Club',
    headerProps: {},
    footerProps: {},
    baseStyles: {},
  };

  render() {
    const {
      helmetTitle,
      children,
      footerProps,
      baseStyles,
      classes,
      hideFooter,
    } = this.props;

    return (
      <div style={baseStyles} className={classes.html}>
        <Helmet title={helmetTitle} />
        {children}
        {!hideFooter && <Footer {...footerProps} />}
      </div>
    );
  }
}

PageTemplate.propTypes = {
  helmetTitle: PropTypes.string,
  headerProps: PropTypes.object,
  children: PropTypes.any.isRequired,
  footerProps: PropTypes.object,
  baseStyles: PropTypes.object,
  classes: PropTypes.object,
  hideFooter: PropTypes.bool,
};

export default withStyles(useStyles)(PageTemplate);
