import React from 'react';
import clsx from 'classnames';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { bool, func, objectOf } from 'prop-types';

import { convertStringToBool } from 'app/redux/utils/convertStringToBool';
import { referralAndLegalSection } from 'app/redux/modules/YourDetails/form';

import { errorsPropType } from 'app/components/modules/YourDetails/propTypes';
import { getFieldError } from 'app/components/modules/YourDetails/getFieldError';
import { TermsAndConditions } from './TermsAndConditions';
import { ErrorMessage } from './ErrorMessage';
import { useStyles } from './styles';

const { TERMS_AND_CONDITIONS } = referralAndLegalSection;

const TermsAndConditionsSection = ({
  displayEecc = true,
  errors,
  pristine,
  onChange,
  onEeccClick,
  checked,
  oneTouchSwitchCheck,
}) => {
  const classes = useStyles();

  const { errorMessage, hasError } = getFieldError({
    name: TERMS_AND_CONDITIONS,
    errors,
    pristine,
  });

  return (
    <>
      <Typography className={classes.sectionLabel} variant="body1">
        Terms and Conditions
      </Typography>
      <FormControlLabel
        classes={{ root: classes.formLabel }}
        control={
          <Checkbox
            classes={{ root: clsx({ [classes.checkboxError]: hasError }) }}
          />
        }
        name={TERMS_AND_CONDITIONS}
        label={
          <TermsAndConditions
            displayEecc={displayEecc}
            onEeccClick={onEeccClick}
            oneTouchSwitchCheck={oneTouchSwitchCheck}
          />
        }
        checked={checked}
        onChange={(_, value) => onChange(convertStringToBool(value))}
      />
      <ErrorMessage hasError={hasError} errorMessage={errorMessage} />
    </>
  );
};

TermsAndConditionsSection.propTypes = {
  displayEecc: bool.isRequired,
  checked: bool.isRequired,
  errors: errorsPropType,
  onChange: func.isRequired,
  onEeccClick: func.isRequired,
  pristine: objectOf(bool),
  oneTouchSwitchCheck: bool.isRequired,
};

export { TermsAndConditionsSection };
