import React from 'react';
import { arrayOf, shape } from 'prop-types';

import { price } from 'app/constants/propTypes';
import { formatPrice } from 'app/redux/utils/formatter';
import { TRY_BEFORE_YOU_BUY_PROMO } from 'app/redux/modules/Broadband/constants';

import { basketItemProps } from './propTypes';
import { addPrices } from 'app/redux/utils/priceUtils';

const TBYBTotalCaption = ({ services, totalPrice, discountedTotalPrice }) => {
  const broadband = services.find(({ id }) => id === 'broadband');

  if (!broadband) {
    return null;
  }

  const {
    serviceRaw: {
      tariff: {
        contract: { promo },
      },
      price,
    },
  } = broadband;

  if (promo?.id === TRY_BEFORE_YOU_BUY_PROMO) {
    const afterPromoPrice = discountedTotalPrice
      ? addPrices(discountedTotalPrice, price)
      : addPrices(totalPrice, price);

    return (
      <>
        (<b>{formatPrice(afterPromoPrice)}</b> after {promo.length} months)
      </>
    );
  }

  return null;
};

TBYBTotalCaption.propTypes = {
  services: arrayOf(shape(basketItemProps)).isRequired,
  discountedTotalPrice: price,
  totalPrice: price.isRequired,
};

export { TBYBTotalCaption };
