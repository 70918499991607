import { makeStyles } from '@material-ui/core';
import { colors } from '@utilitywarehouse/colour-system';

const WRAPPER_HEIGHT_PX = 96;
const NAVIGATION_PANE_HEIGHT_PX = 74;

const useStyles = makeStyles(({ spacing, typography, breakpoints }) => ({
  wrapper: {
    position: 'absolute',
    bottom: NAVIGATION_PANE_HEIGHT_PX,
    width: '100%',
    filter: 'drop-shadow(4px -4px 3px rgba(0, 0, 0, 0.1))',
    borderBottom: `1px solid ${colors.grey100}`,
    transition: 'filter ease 0.3s',

    '&.expanded': {
      filter: 'drop-shadow(4px -4px 3px rgba(0, 0, 0, 0.3))',
    },
  },
  expandWrapper: {
    border: 0,
    margin: 0,
    padding: 0,
    borderRadius: `${spacing(1)}px ${spacing(1)}px 0 0`,
  },
  expandSummary: {
    padding: spacing(2),
  },
  expandSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,

    '&.Mui-expanded': {
      margin: 0,

      '& > $summaryPrice': {
        visibility: 'hidden',
        opacity: 0,
      },
    },
  },
  expandIcon: {
    color: colors.cyan500,
    transform: 'rotate(180deg)',

    '&.Mui-expanded': {
      transform: 'rotate(0deg)',
    },

    '& .MuiSvgIcon-root': {
      width: 40,
      height: 40,
    },
  },
  summaryPrice: {
    marginRight: spacing(0.5),
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.4s, visibility 0.4s',
  },
  title: {
    fontWeight: typography.fontWeightMedium,
    fontSize: 22,
    lineHeight: 0.8,
    color: colors.grey1000,

    [breakpoints.up('sm')]: {
      fontSize: 24,
    },
  },
  content: {
    marginTop: spacing(1),
    padding: spacing(0, 2),
    paddingBottom: spacing(3),
  },
  placeholder: {
    height: WRAPPER_HEIGHT_PX,
  },
}));

export { useStyles };
