import React from 'react';
import { isEmpty } from 'lodash';
import { Divider, Typography } from '@material-ui/core';
import { shape, arrayOf, string, oneOfType, node } from 'prop-types';

import { useStyles } from './styles';

const Heading = ({ children, ...restProps }) => {
  const { heading } = useStyles();

  return (
    <Typography className={heading} variant="h4" {...restProps}>
      {children}
    </Typography>
  );
};

const DiscountsSection = ({ discounts = null }) => {
  const classes = useStyles();

  if (!discounts || isEmpty(discounts)) {
    return null;
  }

  return (
    <>
      <Divider className={classes.divider} />
      <ul className={classes.list}>
        <div>
          {discounts.map(({ label, value }) => (
            <li key={label} className={classes.wrapper}>
              <Heading>{label}</Heading>
              <Heading>-{value}</Heading>
            </li>
          ))}
        </div>
      </ul>
    </>
  );
};

DiscountsSection.propTypes = {
  discounts: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    })
  ),
};

Heading.propTypes = {
  children: oneOfType([node, string]).isRequired,
};

export { DiscountsSection };
