const getFieldError = ({ name, errors, pristine }) => {
  const hasError = Boolean(
    !pristine[name] && errors[name] && !errors[name].valid
  );
  const errorMessage = errors[name]?.reason ?? '';

  return { hasError, errorMessage };
};

export { getFieldError };
