import { makeStyles } from '@material-ui/core';
import { colors } from '@utilitywarehouse/colour-system';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    '& + &': {
      marginTop: spacing(3),
    },
  },
  leftContainer: {
    display: 'flex',
    flex: 2,
    marginRight: spacing(3),
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    flex: 1,
  },
  text: {
    color: colors.grey1000,

    '& + &': {
      marginTop: spacing(0.5),
    },
  },
  bolder: {
    fontWeight: 600,
  },
  oneTimeCost: {
    textAlign: 'right',
  },
  bodyText: {
    [breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  heading: {
    [breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  greenText: {
    color: colors.green600,
  },
}));

export { useStyles };
