import React from 'react';
import { func, bool } from 'prop-types';
import { Dialog, Typography, IconButton } from '@material-ui/core';
import { WarningOutlined, CloseIcon } from '@utilitywarehouse/partner-ui-icons';

import Button from 'components/modules/Shared/Button';
import Link from 'components/modules/Shared/Link';
import { useStyles } from './styles';

const SubmitDeniedDialog = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} id="submit-denied-dialog">
      <div className={classes.container}>
        <WarningOutlined className={classes.icon} color="error" />
        <IconButton
          aria-label="Close dialog"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon color="secondary" />
        </IconButton>

        <Typography variant="h3" color="primary">
          Give us a call to continue
        </Typography>
        <Typography align="center">
          We&rsquo;re sorry, but we can&rsquo;t sign you up online right now. To
          continue, please give our friendly customer services team a call on{' '}
          <Link href="tel:03337773212">0333 777 3212</Link> and select option 3
          so they can help you with your application.
        </Typography>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </div>
    </Dialog>
  );
};

SubmitDeniedDialog.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
};

export { SubmitDeniedDialog };
