import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  Typography,
  IconButton,
  InputAdornment,
  Paper,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';

import PaperBillingDialog from 'components/modules/YourDetails/Dialog/PaperBillingDialog';
import Button from 'components/modules/Shared/Button';
import TextField from 'modules/CustomerUI/TextField';
import { getFieldError } from 'app/components/modules/YourDetails/getFieldError';
import {
  ABOUT_YOU_TITLES,
  PASSWORD_MIN_LENGTH,
} from 'redux/modules/YourDetails/constants';
import { aboutYouSection } from 'redux/modules/YourDetails/form';

import { AddtionalAccountHolderSection } from './AdditionalAccountHolderSection';
import { CashbackSection } from './CashbackSection';
import { DobPicker } from './DobPicker';
import { HomeMoveSection } from './HomeMoveSection';
import useStyles from './styles';

const {
  ABOUT_YOU_TITLE,
  ABOUT_YOU_NAME_FIRST,
  ABOUT_YOU_NAME_LAST,
  ABOUT_YOU_DATE_OF_BIRTH,
  ABOUT_YOU_CONTACT_NUMBER,
  ABOUT_YOU_EMAIL,
  ABOUT_YOU_PASSWORD,
  ABOUT_YOU_PAPER_BILLING_CONFIRM,
} = aboutYouSection;

const AboutYou = ({
  errors,
  fields,
  fieldPristine,
  hasCashback,
  onContinuePaperBilling,
  paperBillingModal,
  setPaperBillingModal,
  updateForm,
  isSales,
  isTalkTalk,
  requestEeccContract,
  eeccContractLoading,
  displayEecc,
}) => {
  const classes = useStyles();

  const handlePaperBillingConfirm = () => {
    setPaperBillingModal(false);
    updateForm(ABOUT_YOU_PAPER_BILLING_CONFIRM, true);
    onContinuePaperBilling();
  };

  // common form field props, set here so the structure/logic
  // is not repeated for each field over and over again
  const getFieldProps = (name) => {
    const { hasError, errorMessage } = getFieldError({
      name,
      errors,
      pristine: fieldPristine,
    });
    return {
      name,
      onChange: (_, value) => updateForm(name, value),
      value: fields[name],
      error: hasError,
      errorMessage,
    };
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const tick = (fieldId) =>
    errors[fieldId]?.valid && fields[fieldId] ? (
      <FlagPointIcon className={classes.validIcon} />
    ) : null;

  return (
    <Paper classes={{ root: classes.wrapper }}>
      {paperBillingModal?.open && (
        <PaperBillingDialog
          open={paperBillingModal.open}
          variant={paperBillingModal.variant}
          onClose={() => setPaperBillingModal(false)}
          onConfirm={handlePaperBillingConfirm}
        />
      )}
      <Typography className={classes.heading} variant="h3" color="primary">
        About you
      </Typography>
      <TextField
        label="Title"
        select
        className={classes.textInput}
        {...getFieldProps(ABOUT_YOU_TITLE)}
      >
        {ABOUT_YOU_TITLES.map((title) => (
          <MenuItem value={title} key={title} data-test-id={title}>
            {title}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className={classes.textInput}
        label="First name"
        {...getFieldProps(ABOUT_YOU_NAME_FIRST)}
      />
      <TextField
        className={classes.textInput}
        label="Last name"
        {...getFieldProps(ABOUT_YOU_NAME_LAST)}
      />
      <DobPicker
        className={classes.textInput}
        keyboardIcon={tick(ABOUT_YOU_DATE_OF_BIRTH)}
        {...getFieldProps(ABOUT_YOU_DATE_OF_BIRTH)}
      />
      <TextField
        className={classes.textInput}
        label="Contact number"
        {...getFieldProps(ABOUT_YOU_CONTACT_NUMBER)}
        InputProps={{
          endAdornment: tick(ABOUT_YOU_CONTACT_NUMBER),
        }}
        inputProps={{ inputMode: 'numeric' }}
      />
      <div className={classes.textInputButtonWrapper}>
        <TextField
          className={classes.textInput}
          label="Email address"
          {...getFieldProps(ABOUT_YOU_EMAIL)}
          InputProps={{ endAdornment: tick(ABOUT_YOU_EMAIL) }}
        />
        {(isSales || isTalkTalk) && displayEecc && (
          <Button
            variant="outlined"
            size="large"
            className={classes.eeccButton}
            onClick={() => requestEeccContract()}
            loading={eeccContractLoading}
            disabled={!fields[ABOUT_YOU_EMAIL]}
          >
            Send contract summary
          </Button>
        )}
      </div>
      <TextField
        className={classes.textInput}
        type={showPassword ? 'text' : 'password'}
        label="Choose your account password"
        helperText={`At least ${PASSWORD_MIN_LENGTH} characters, 1 upper case letter, 1 number`}
        {...getFieldProps(ABOUT_YOU_PASSWORD)}
        InputProps={{
          endAdornment: (
            <>
              {tick(ABOUT_YOU_PASSWORD)}
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />

      <HomeMoveSection
        fields={fields}
        updateForm={updateForm}
        fieldPristine={fieldPristine}
        errors={errors}
      />

      <AddtionalAccountHolderSection
        fields={fields}
        updateForm={updateForm}
        getFieldProps={getFieldProps}
      />

      <CashbackSection
        hasCashback={hasCashback}
        fields={fields}
        updateForm={updateForm}
        getFieldProps={getFieldProps}
        fieldPristine={fieldPristine}
      />
    </Paper>
  );
};

AboutYou.propTypes = {
  updateForm: PropTypes.func.isRequired,
  setPaperBillingModal: PropTypes.func.isRequired,
  fields: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  fieldPristine: PropTypes.objectOf(PropTypes.bool),
  errors: PropTypes.objectOf(
    PropTypes.shape({
      valid: PropTypes.bool.isRequired,
      reason: PropTypes.string,
      field: PropTypes.string.isRequired,
    })
  ),
  onContinuePaperBilling: PropTypes.func.isRequired,
  paperBillingModal: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string,
  }).isRequired,
  hasCashback: PropTypes.bool,
  isSales: PropTypes.bool.isRequired,
  requestEeccContract: PropTypes.func.isRequired,
  eeccContractLoading: PropTypes.bool.isRequired,
  displayEecc: PropTypes.bool.isRequired,
};

export default AboutYou;
