import React from 'react';
import { uniqueId } from 'lodash';
import { Link as LinkUnwrapped } from '@material-ui/core';
import { string, node, func } from 'prop-types';
import { useStyles } from './styles';

const Link = ({ href, children, onClick = null }) => {
  const classes = useStyles();

  if (onClick) {
    const handleClick = (event) => {
      event.preventDefault();
      onClick();
    };

    return (
      <LinkUnwrapped
        className={classes.link}
        onClick={handleClick}
        href={`#${uniqueId('skipRedirect')}`}
      >
        {children}
      </LinkUnwrapped>
    );
  }

  return (
    <LinkUnwrapped
      className={classes.link}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </LinkUnwrapped>
  );
};

Link.propTypes = {
  href: string,
  children: node.isRequired,
  onClick: func,
};

export { Link };
