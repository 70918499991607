import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { WarningOutlined } from '@utilitywarehouse/partner-ui-icons';
import Button from 'modules/Shared/Button';
import {
  PAPER_BILLING_EO,
  PAPER_BILLING_DEFAULT,
} from 'redux/modules/YourDetails/constants';
import useStyles from './styles';

const PaperBillingEO = () => (
  <>
    <Typography variant="h3" color="primary">
      Are you sure you want paper bills?
    </Typography>
    <Typography component="div">
      <p>
        If possible, please enter an email address so we can send your bills in
        the most convenient and environmentally-friendly way. You’ll be able to
        download and view them whenever you like, free of charge.
      </p>

      <p>
        If you don’t provide an email address, we’ll send your monthly bill in
        the post to your address. If you add any more of our services, you’ll be
        charged <strong>£4 a month</strong> for paper bills.
      </p>
    </Typography>
  </>
);

const PaperBillingDefault = () => (
  <>
    <Typography variant="h3" color="primary">
      Paper bills will cost you £4 a month
    </Typography>
    <Typography component="div">
      <p>
        If possible, please enter an email address so we can send your bills in
        the most convenient and environmentally-friendly way. You’ll be able to
        download and view them whenever you like, free of charge.
      </p>
      <p>
        If you don’t provide an email address, we’ll send your monthly bill in
        the post to your address and you’ll be charged{' '}
        <strong>£4 a month</strong>.
      </p>
    </Typography>
  </>
);

const PaperBillingDialog = ({ open = false, onClose, onConfirm, variant }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      title={
        <div className={classes.iconContainer}>
          <WarningOutlined className={classes.icon} />
        </div>
      }
    >
      <DialogContent className={classes.container}>
        <IconButton
          color="secondary"
          onClick={onClose}
          className={classes.closeButton}
        >
          <Close className={classes.closeIcon} />
        </IconButton>
        <WarningOutlined className={classes.icon} />
        {variant === PAPER_BILLING_EO && <PaperBillingEO />}
        {variant === PAPER_BILLING_DEFAULT && <PaperBillingDefault />}
        <Button
          variant="contained"
          size="large"
          onClick={onClose}
          color="secondary"
        >
          Enter email address
        </Button>
        <Button onClick={onConfirm} variant="text">
          Continue without email
        </Button>
      </DialogContent>
    </Dialog>
  );
};

PaperBillingDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default PaperBillingDialog;
