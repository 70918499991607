import React from 'react';
import DatePicker from 'app/components/modules/Shared/DatePicker';

const DobPicker = (props) => (
  <DatePicker
    {...props}
    label="Date of birth"
    helperText="DD/MM/YYYY"
    KeyboardButtonProps={{ disabled: true }}
    inputProps={{ inputMode: 'numeric' }}
  />
);

export { DobPicker };
