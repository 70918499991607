import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, typography }) => ({
  wrapper: {
    marginBottom: spacing(3),
  },
  label: {
    marginBottom: spacing(1),
    fontWeight: typography.fontWeightMedium,
  },
}));

export { useStyles };
