const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles(({ spacing }) => ({
  alert: {
    padding: spacing(1, 2),
    '& > .MuiAlert-action': {
      padding: 0,
    },
  },
}));

export { useStyles };
