import { makeStyles } from '@material-ui/core';
import { colors } from '@utilitywarehouse/colour-system';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  divider: {
    margin: spacing(3, 0),
  },
  list: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  heading: {
    color: colors.green600,

    [breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
}));

export { useStyles };
