import { makeStyles } from '@material-ui/core';
import { colors } from '@utilitywarehouse/colour-system';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderTop: `1px solid ${colors.grey100}`,
    padding: spacing(2),
  },
  title: {
    color: colors.grey1000,

    [breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    gap: spacing(1),
    paddingTop: spacing(0.5),
  },
}));

export { useStyles };
