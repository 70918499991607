import React from 'react';
import PropTypes from 'prop-types';

import RadioButtonGroup from 'app/components/modules/Shared/MultiSelection/RadioButtonGroup';
import { convertStringToBool } from 'app/redux/utils/convertStringToBool';
import { aboutYouSection } from 'redux/modules/YourDetails/form';
import { YourDetailsYourAddressPreviousPicker } from 'app/redux/modules/YourDetails/containers/YourAddress';

import { YES_NO_OPTIONS } from '../../constants';
import useStyles from '../styles';

const {
  ABOUT_YOU_RECENT_MOVE,
  ABOUT_YOU_OWNER,
  ABOUT_YOU_PREVIOUS_ADDRESS,
} = aboutYouSection;

const HomeMoveSection = ({ fields, updateForm, fieldPristine, errors }) => {
  const classes = useStyles();

  const showPreviousAddressPicker =
    fields[ABOUT_YOU_RECENT_MOVE] && fields[ABOUT_YOU_OWNER] === false;

  return (
    <>
      <RadioButtonGroup
        radioGroupClasses={{
          container: classes.formAdditionalSelect,
          radioGroup: classes.formAdditionalSelectItems,
          radioButton: classes.formAdditionalSelectItem,
        }}
        label="Did you move into this address within the last six months?"
        options={YES_NO_OPTIONS}
        onChange={(_, value) =>
          updateForm(ABOUT_YOU_RECENT_MOVE, convertStringToBool(value))
        }
        value={fields[ABOUT_YOU_RECENT_MOVE]}
        name={ABOUT_YOU_RECENT_MOVE}
      />

      {fields[ABOUT_YOU_RECENT_MOVE] && (
        <RadioButtonGroup
          radioGroupClasses={{
            container: classes.formAdditionalSelect,
            radioGroup: classes.formAdditionalSelectItems,
            radioButton: classes.formAdditionalSelectItem,
          }}
          label="Did you own your previous home?"
          options={[
            { label: 'Yes', value: false },
            { label: 'No', value: true },
          ]}
          onChange={(_, value) =>
            updateForm(ABOUT_YOU_OWNER, convertStringToBool(value))
          }
          value={fields[ABOUT_YOU_OWNER]}
          name={ABOUT_YOU_OWNER}
        />
      )}
      {showPreviousAddressPicker && (
        <YourDetailsYourAddressPreviousPicker
          classes={{ addressPicker: classes.textInput }}
          onSelectAddress={(address) =>
            updateForm(ABOUT_YOU_PREVIOUS_ADDRESS, address)
          }
          postcodeLabel="Your previous postcode"
          resultsLabel="Your previous address"
          errorMessage={
            !fieldPristine[ABOUT_YOU_PREVIOUS_ADDRESS] &&
            errors[ABOUT_YOU_PREVIOUS_ADDRESS]?.reason
          }
        />
      )}
    </>
  );
};

HomeMoveSection.propTypes = {
  fields: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  updateForm: PropTypes.func.isRequired,
  fieldPristine: PropTypes.objectOf(PropTypes.bool),
  errors: PropTypes.objectOf(
    PropTypes.shape({
      valid: PropTypes.bool.isRequired,
      reason: PropTypes.string,
      field: PropTypes.string.isRequired,
    })
  ),
};

export { HomeMoveSection };
