import { makeStyles } from '@material-ui/core/styles';

import { INPUTS_MAX_WIDTH_PX } from '../constants';

const style = ({ spacing, palette, breakpoints }) => ({
  alert: {
    marginTop: spacing(3),
    padding: spacing(1, 2),
  },
  heading: {
    [breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  formAdditionalSelect: {
    marginTop: spacing(2),
  },
  formAdditionalSelectItems: {
    display: 'flex',
    maxWidth: INPUTS_MAX_WIDTH_PX,
  },
  formAdditionalSelectItem: {
    flexGrow: 1,
  },
  validIcon: {
    color: palette.success.main,
    margin: spacing(1.5),
  },
  textInput: {
    width: '100%',
    maxWidth: INPUTS_MAX_WIDTH_PX,
  },
  textInputButtonWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: spacing(2),
    flexWrap: 'wrap',

    '& > *:first-child': {
      flexBasis: 320,
    },
  },
  eeccButton: {
    marginBottom: 4,
    flexShrink: 0,
  },
  wrapper: {
    padding: spacing(3),
    paddingBottom: spacing(2),
    borderRadius: spacing(1),
  },
});

export default makeStyles(style);
