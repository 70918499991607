const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing(2, 0),
    margin: spacing(3, 2),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  icon: {
    width: 48,
    height: 48,
  },
}));

export { useStyles };
