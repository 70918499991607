import React, { useCallback } from 'react';
import {
  bool,
  func,
  string,
  object,
  arrayOf,
  objectOf,
  shape,
} from 'prop-types';

import AddressPicker from 'modules/Shared/AddressPickerNew';

const BillingAddressPicker = ({
  addressPicker,
  getAddressList,
  onSelectAddress,
  setManualEntry,
  setManualEntryField,
  setAddressSelection,
}) => {
  const setAddresses = useCallback(
    (address) => {
      onSelectAddress(address);
      setAddressSelection(address);
    },
    [onSelectAddress, setAddressSelection]
  );

  return (
    <AddressPicker
      postcode={addressPicker.postcode}
      addresses={addressPicker.addressList}
      loading={addressPicker?.loading}
      selectedAddress={addressPicker.selectedAddress}
      onChangePostcode={getAddressList}
      onSelectAddress={setAddresses}
      manualEntry={addressPicker.manualEntry}
      onSelectManualEntry={setManualEntry}
      onChangeAddressField={setManualEntryField}
      addressFields={addressPicker.addressFields}
      PickerComponent="div"
    />
  );
};

BillingAddressPicker.propTypes = {
  getAddressList: func.isRequired,
  onSelectAddress: func.isRequired,
  setAddressSelection: func.isRequired,
  setManualEntry: func.isRequired,
  setManualEntryField: func.isRequired,
  addressPicker: shape({
    loading: bool.isRequired,
    addressList: arrayOf(object),
    selectedAddress: string,
    postcode: string,
    manualEntry: bool,
    addressFields: objectOf(string),
  }),
};

export { BillingAddressPicker };
