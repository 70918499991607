import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Deprecated } from 'app/lib/propTypes';
import styles from './styles';

const ALTERNATE_BACKGROUND_COLOR = 'grey';

const SectionCentered = (
  {
    backgroundColor,
    children,
    classes,
    sectionCenteredClasses = {},
    containerStyle,
    id,
    style,
    newLayout,
    component = 'div',
  },
  ref
) => {
  const isAlternate = backgroundColor === ALTERNATE_BACKGROUND_COLOR;
  const Component = component;

  return (
    <Component
      id={id}
      ref={ref}
      className={classNames(
        classes.root,
        sectionCenteredClasses.root,
        isAlternate && classes.alternate,
        {
          [classes.newRoot]: newLayout,
          [classes.white]: backgroundColor === 'white',
        }
      )}
      style={style}
    >
      <div
        className={classNames(
          classes.container,
          sectionCenteredClasses.container,
          {
            [classes.newContainer]: newLayout,
          }
        )}
        style={containerStyle}
      >
        {children}
      </div>
    </Component>
  );
};

const ForwardedSectionCentered = React.forwardRef(SectionCentered);

ForwardedSectionCentered.defaultProps = {
  newLayout: false,
};

ForwardedSectionCentered.propTypes = {
  backgroundColor: Deprecated(PropTypes.oneOf(['white', 'grey'])),
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  containerStyle: Deprecated(PropTypes.object),
  id: PropTypes.string,
  sectionCenteredClasses: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
  }),
  style: Deprecated(PropTypes.object),
  newLayout: PropTypes.bool,
  component: PropTypes.string,
};

export default withStyles(styles)(ForwardedSectionCentered);
