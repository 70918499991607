import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  wrapper: {
    padding: spacing(3),
    borderRadius: spacing(1),
  },
  heading: {
    [breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
}));

export { useStyles };
