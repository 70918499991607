import { makeStyles } from '@material-ui/core/styles';

const ERROR_HEIGHT_PX = 26;

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  sectionLabel: {
    fontWeight: typography.fontWeightMedium,
    marginBottom: spacing(1),
  },
  formLabel: {
    '& > .MuiCheckbox-root': {
      alignSelf: 'flex-start',
    },
  },
  label: {
    paddingTop: spacing(1),
    fontSize: typography.body2.fontSize,
  },
  error: {
    marginTop: spacing(1),
    fontSize: typography.caption.fontSize,
  },
  errorPlaceholder: {
    height: ERROR_HEIGHT_PX,
  },
  checkboxError: {
    color: palette.error.main,
  },
}));

export { useStyles };
