import React from 'react';
import { isEmpty } from 'lodash';
import { Divider } from '@material-ui/core';
import { shape, arrayOf } from 'prop-types';

import { basketItemProps } from '../propTypes';
import { Item } from '../Item';
import { useStyles } from './styles';

const AddOnsSection = ({ addOns = null }) => {
  const classes = useStyles();

  if (!addOns || isEmpty(addOns)) {
    return null;
  }

  return (
    <>
      <Divider className={classes.divider} />
      <ul className={classes.list}>
        {addOns.map(({ id, items, priceValue, oneTimeCost }) => (
          <Item
            key={id}
            id={id}
            items={items}
            priceValue={priceValue}
            oneTimeCost={oneTimeCost}
          />
        ))}
      </ul>
    </>
  );
};

AddOnsSection.propTypes = {
  addOns: arrayOf(shape(basketItemProps)),
};

export { AddOnsSection };
