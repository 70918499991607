import { makeStyles } from '@material-ui/core';
import { colors } from '@utilitywarehouse/colour-system';

const BASKET_MAX_WIDTH_PX = 600;

const useStyles = makeStyles((theme) => ({
  title: {
    color: colors.grey1000,
    marginBottom: theme.spacing(3),
  },
  paper: {
    position: 'sticky',
    top: theme.spacing(3),
    flex: 1,
    maxWidth: BASKET_MAX_WIDTH_PX,
    borderRadius: theme.spacing(1),
  },
  wrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

export { useStyles };
