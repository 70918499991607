import React from 'react';
import { Typography } from '@material-ui/core';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';

import { useStyles } from './styles';

const PreferencesSavedConfirmation = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <FlagPointIcon className={classes.icon} />
      <Typography variant="inherit" component="p">
        We’ve saved your contact preferences.
      </Typography>
    </div>
  );
};

export { PreferencesSavedConfirmation };
