import { bool, objectOf, string, shape, arrayOf } from 'prop-types';

const errorsPropType = objectOf(
  shape({
    valid: bool.isRequired,
    reason: string,
    field: string.isRequired,
  })
);

const formErrorsPropType = arrayOf(
  shape({
    id: string.isRequired,
    message: string.isRequired,
  })
);

export { errorsPropType, formErrorsPropType };
