import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  list: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
}));

export { useStyles };
