import React from 'react';
import { Typography } from '@material-ui/core';
import { bool, func } from 'prop-types';

import {
  GENERAL_TERMS_URL,
  PRIVACY_URL,
  TARIFF_INFO_URL,
} from 'app/config/externalLinks';

import { EeccTerms } from '../EeccTerms';
import { Link } from '../Link';
import { useStyles } from './styles';

const TermsAndConditions = ({
  displayEecc,
  onEeccClick,
  oneTouchSwitchCheck,
}) => {
  const classes = useStyles();
  const oneTouchSwitchText =
    ' I understand what will happen when I switch my Broadband services to UW and provide my express consent to continue.';
  return (
    <Typography className={classes.label}>
      I have read and understood the{' '}
      <Link href={GENERAL_TERMS_URL}>Terms and Conditions</Link>,{' '}
      <Link href={TARIFF_INFO_URL}>Tariff Information</Link> and the{' '}
      <Link href={PRIVACY_URL}>Privacy Notice</Link>
      {displayEecc && <EeccTerms onClick={onEeccClick} />}.
      {oneTouchSwitchCheck && oneTouchSwitchText} By submitting my order, I
      confirm that I accept the Terms and Conditions and that I have an
      obligation to pay for the services ordered. I want my services to start as
      soon as possible and I agree to pay for the services I use if I later
      cancel early.
    </Typography>
  );
};

TermsAndConditions.propTypes = {
  displayEecc: bool.isRequired,
  onEeccClick: func.isRequired,
};

export { TermsAndConditions };
