import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AddressPicker from 'modules/Shared/AddressPickerNew';

const YourAddressPicker = ({
  addressPicker,
  getAddressList,
  onSelectAddress,
  setManualEntry,
  setManualEntryField,
  setAddressSelection,
  postcodeLabel,
  resultsLabel,
  testIds = {
    postcode: null,
    results: null,
  },
  classes = {},
  errorMessage,
}) => {
  const setAddresses = useCallback(
    (address) => {
      onSelectAddress(address);
      setAddressSelection(address);
    },
    [onSelectAddress, setAddressSelection]
  );

  return (
    <AddressPicker
      postcode={addressPicker?.postcode}
      addresses={addressPicker?.addressList}
      loading={addressPicker?.loading}
      selectedAddress={addressPicker.selectedAddress}
      onChangePostcode={(postcode) => getAddressList(postcode)}
      onSelectAddress={setAddresses}
      manualEntry={addressPicker.manualEntry}
      onSelectManualEntry={setManualEntry}
      onChangeAddressField={setManualEntryField}
      addressFields={addressPicker.addressFields}
      classes={{ root: classes.addressPicker }}
      PostcodeInputProps={{
        dataTestId: testIds.postcode,
        label: postcodeLabel,
        error: !!errorMessage,
        helperText: errorMessage,
      }}
      AddressResultsProps={{
        dataTestId: testIds.results,
        label: resultsLabel,
      }}
      PickerComponent="div"
    />
  );
};

YourAddressPicker.propTypes = {
  getAddressList: PropTypes.func.isRequired,
  onSelectAddress: PropTypes.func.isRequired,
  setAddressSelection: PropTypes.func.isRequired,
  setManualEntry: PropTypes.func.isRequired,
  setManualEntryField: PropTypes.func.isRequired,
  addressPicker: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    addressList: PropTypes.arrayOf(PropTypes.object),
    selectedAddress: PropTypes.string,
    postcode: PropTypes.string,
    manualEntry: PropTypes.bool,
    addressFields: PropTypes.objectOf(PropTypes.string),
  }),
  testIds: PropTypes.shape({
    postcode: PropTypes.string,
    results: PropTypes.string,
  }),
  postcodeLabel: PropTypes.string,
  resultsLabel: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
};

export default YourAddressPicker;
