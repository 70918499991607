import React from 'react';
import { bool, func } from 'prop-types';
import { Typography } from '@material-ui/core';

import { PRIVACY_URL } from 'app/config/externalLinks';

import { Link } from '../Link';
import { OptOutModal } from './OptOutModal';
import { useMarketingModalForm } from './useMarketingModalForm';
import { useStyles } from './styles';
import { PreferencesSavedConfirmation } from './PreferencesSavedConfirmation';

const getText = (isOptOut) =>
  isOptOut
    ? "If you'd like to opt back in,"
    : "We'll keep in touch about how you could save even more time and money on your bills, as well as other relevant updates. You can opt out at any time, but if you'd rather not hear from us,";

const MarketingPreferencesSection = ({ isOptOut, onOptChange }) => {
  const classes = useStyles();
  const {
    isModalOpen,
    handleModalOpen,
    handleModalClose,
    handleOptCheck,
  } = useMarketingModalForm(onOptChange);

  const hasOptionSelected = isOptOut !== null;

  return (
    <div className={classes.container}>
      {hasOptionSelected && <PreferencesSavedConfirmation />}
      <Typography variant="inherit" component="p">
        {getText(isOptOut)} please{' '}
        <Link onClick={handleModalOpen}>let us know</Link>. Learn more about how
        we use your data in our <Link href={PRIVACY_URL}>Privacy Notice</Link>.
      </Typography>

      <OptOutModal
        isOpen={isModalOpen}
        isOptOut={isOptOut}
        onClose={handleModalClose}
        onOptCheck={handleOptCheck}
      />
    </div>
  );
};

MarketingPreferencesSection.propTypes = {
  isOptOut: bool,
  onOptChange: func.isRequired,
};

export { MarketingPreferencesSection };
