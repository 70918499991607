import React from 'react';
import { isEmpty } from 'lodash';
import { string, bool } from 'prop-types';

import ErrorMessageUnwrapped from 'app/components/modules/Shared/ErrorMessage';

import { useStyles } from './styles';

const ErrorMessage = ({ errorMessage = '', hasError }) => {
  const classes = useStyles();

  if (!hasError || isEmpty(errorMessage)) {
    return <div className={classes.errorPlaceholder}></div>;
  }

  return (
    <ErrorMessageUnwrapped message={errorMessage} className={classes.error} />
  );
};

ErrorMessage.propTypes = {
  errorMessage: string,
  hasError: bool.isRequired,
};

export { ErrorMessage };
