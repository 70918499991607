import React from 'react';
import { Paper, Typography } from '@material-ui/core';

import { basketProps } from '../propTypes';
import { TotalPrice } from '../TotalPrice';
import { ServicesSection } from '../ServicesSection';
import { AddOnsSection } from '../AddOnsSection';
import { useStyles } from './styles';
import { DiscountsSection } from '../DiscountsSection';

const Desktop = ({
  addOns,
  discountedTotalPrice,
  services,
  totalPrice,
  discounts,
  totalText,
}) => {
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.paper }}>
      <div className={classes.wrapper}>
        <Typography className={classes.title} variant="h3">
          Monthly bill
        </Typography>
        <ServicesSection services={services} />
        <AddOnsSection addOns={addOns} />
        <DiscountsSection discounts={discounts} />
      </div>
      <TotalPrice
        discountedTotalPrice={discountedTotalPrice}
        totalPrice={totalPrice}
        text={totalText}
      />
    </Paper>
  );
};

Desktop.propTypes = basketProps;

export { Desktop };
