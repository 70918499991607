import React from 'react';
import { Paper } from '@material-ui/core';
import { bool, func, string, shape, objectOf, oneOfType } from 'prop-types';

import { MarketingPreferencesSection } from './MarketingPreferencesSection';
import { TermsAndConditionsSection } from './TermsAndConditionsSection';
import { CoolingOffSection } from './CoolingOffSection';
import { ReferralForm } from './ReferralForm';
import { referralAndLegalSection } from 'app/redux/modules/YourDetails/form';
import { useStyles } from './styles';

const { TERMS_AND_CONDITIONS } = referralAndLegalSection;

const ReferralAndLegalFormSection = ({
  displayEecc,
  errors,
  marketing,
  hasMultipleServices,
  isReferralEnabled,
  onFormChange,
  onEeccClick,
  onMarketingOptChange,
  onTermsChecked,
  pristine,
  values,
  oneTouchSwitchTermsAndConditionsState,
}) => {
  const classes = useStyles();
  const oneTouchSwitchCheck = oneTouchSwitchTermsAndConditionsState;

  return (
    <Paper classes={{ root: classes.wrapper }}>
      {isReferralEnabled && (
        <ReferralForm
          errors={errors}
          onFormChange={onFormChange}
          pristine={pristine}
          values={values}
        />
      )}
      <MarketingPreferencesSection
        isOptOut={marketing}
        onOptChange={onMarketingOptChange}
      />
      <CoolingOffSection hasMultipleServices={hasMultipleServices} />
      <TermsAndConditionsSection
        checked={values[TERMS_AND_CONDITIONS]}
        displayEecc={displayEecc}
        errors={errors}
        onChange={(checked) => onTermsChecked(checked)}
        onEeccClick={onEeccClick}
        pristine={pristine}
        oneTouchSwitchCheck={oneTouchSwitchCheck}
      />
    </Paper>
  );
};

ReferralAndLegalFormSection.propTypes = {
  displayEecc: bool.isRequired,
  errors: objectOf(
    shape({
      valid: bool.isRequired,
      reason: string,
      field: string.isRequired,
    })
  ),
  marketing: bool,
  hasMultipleServices: bool.isRequired,
  isReferralEnabled: bool.isRequired,
  onFormChange: func.isRequired,
  onEeccClick: func.isRequired,
  onTermsChecked: func.isRequired,
  onMarketingOptChange: func.isRequired,
  pristine: objectOf(bool),
  values: objectOf(oneOfType([string, bool])).isRequired,
  oneTouchSwitchTermsAndConditionsState: bool.isRequired,
};

export { ReferralAndLegalFormSection };
