import React from 'react';
import { bool, objectOf, string, oneOfType, func } from 'prop-types';

import { directDebitSection } from 'redux/modules/YourDetails/form';
import TextField from 'modules/CustomerUI/TextField';
import MaskedTextField from 'modules/Shared/MaskedTextField';
import { getFieldError } from 'app/components/modules/YourDetails/getFieldError';
import { errorsPropType } from 'app/components/modules/YourDetails/propTypes';
import { useStyles } from './styles';

const {
  DIRECT_DEBIT_NAME,
  DIRECT_DEBIT_SORT_CODE,
  DIRECT_DEBIT_ACCOUNT_NUMBER,
} = directDebitSection;

const DirectDebitForm = ({ errors, onFormChange, pristine, values }) => {
  const classes = useStyles();

  const getFieldProps = (name) => {
    const { hasError, errorMessage } = getFieldError({
      name,
      errors,
      pristine,
    });

    return {
      name,
      onChange: (_, value) => onFormChange(name, value),
      value: values[name],
      error: hasError,
      errorMessage,
    };
  };

  return (
    <div className={classes.inputsContainer}>
      <TextField
        fullWidth
        label="Account holder name"
        {...getFieldProps(DIRECT_DEBIT_NAME)}
      />
      <MaskedTextField
        autoComplete="off"
        noState
        mask="99-99-99"
        type="tel"
        useCustomerUI
        standardChangeArgs
        fullWidth
        label="Sort code"
        helperText="6 digits"
        placeholder="11-11-11"
        {...getFieldProps(DIRECT_DEBIT_SORT_CODE)}
      />
      <MaskedTextField
        autoComplete="off"
        noState
        mask="99999999"
        type="tel"
        useCustomerUI
        standardChangeArgs
        fullWidth
        label="Account number"
        placeholder="12345678"
        helperText="8 digits"
        {...getFieldProps(DIRECT_DEBIT_ACCOUNT_NUMBER)}
      />
    </div>
  );
};

DirectDebitForm.propTypes = {
  errors: errorsPropType,
  onFormChange: func.isRequired,
  pristine: objectOf(bool),
  values: objectOf(oneOfType([string, bool])).isRequired,
};

export { DirectDebitForm };
