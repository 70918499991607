import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import SectionCentered from 'modules/layout/SectionCentered';
import HelpSection from 'redux/modules/HelpSection/container';
import styles from './styles';

const Footer = ({ cashBack, classes }) => (
  <div className={classes.bgContainer}>
    <SectionCentered>
      <HelpSection cashBack={cashBack} />
      <img
        className={classes.logo}
        alt="footer logo"
        src="images/footer/footerLogo.svg"
      />
      <Typography variant="caption">
        © Utility Warehouse Limited {new Date().getFullYear()}. Registered in
        England. Company number: 04594421. Utility Warehouse Limited is
        authorised and regulated by the Financial Conduct Authority
      </Typography>
    </SectionCentered>
  </div>
);
Footer.propTypes = {
  cashBack: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
