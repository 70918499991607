import { makeStyles } from '@material-ui/core/styles';

const MODAL_MAX_WIDTH_PX = 500;

const useStyles = makeStyles(({ spacing, typography, breakpoints }) => ({
  content: {
    maxWidth: MODAL_MAX_WIDTH_PX,
  },
  heading: {
    fontSize: typography.h5.fontSize,
    marginBottom: spacing(2),
  },
  radioGroup: {
    gap: spacing(0.5),
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: spacing(2),
    padding: spacing(0, 3, 3),
  },
  button: {
    [breakpoints.up('md')]: {
      padding: spacing(1.5, 4),
    },
  },
}));

export { useStyles };
