import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'start',
    padding: spacing(3, 0),
    gap: spacing(3),
  },
  formColumn: {
    display: 'flex',
    gap: spacing(3),
    flex: 2,
    flexDirection: 'column',
  },
  form: {
    position: 'relative',
  },
}));

export { useStyles };
