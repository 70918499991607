import React from 'react';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { bool, func } from 'prop-types';

import { useStyles } from './styles';

const OptOutModal = ({ isOpen, isOptOut, onClose, onOptCheck }) => {
  const classes = useStyles();
  const hasOptionSelected = isOptOut !== null;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent classes={{ root: classes.content }}>
        <Typography variant="h4" className={classes.heading} color="primary">
          Are you sure?
        </Typography>{' '}
        <Typography variant="body1">
          If you choose not to hear from us, we won't be able to let you know
          how you could save more.
        </Typography>
      </DialogContent>
      <div className={classes.actions}>
        <RadioGroup
          classes={{ root: classes.radioGroup }}
          value={isOptOut}
          onChange={onOptCheck}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            data-test-id="OptOut"
            label="Opt me out - I don't mind missing out on savings"
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Keep me up to date on new ways to save"
          />
        </RadioGroup>
        <Button
          className={classes.button}
          disabled={!hasOptionSelected}
          size="large"
          variant="contained"
          onClick={onClose}
          data-test-id="MarketingConfirmButton"
        >
          Confirm
        </Button>
      </div>
    </Dialog>
  );
};

OptOutModal.propTypes = {
  isOpen: bool.isRequired,
  isOptOut: bool,
  onClose: func.isRequired,
  onOptCheck: func.isRequired,
};

export { OptOutModal };
