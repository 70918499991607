import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {
    padding: spacing(3),
    paddingBottom: spacing(2),
    borderRadius: spacing(1),
  },
}));

export { useStyles };
