import React, { useState } from 'react';
import clsx from 'classnames';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import PriceWithDiscount from 'app/components/modules/Shared/PriceWithDiscount';

import { basketProps } from '../propTypes';
import { TotalPrice } from '../TotalPrice';
import { ServicesSection } from '../ServicesSection';
import { AddOnsSection } from '../AddOnsSection';
import { useStyles } from './styles';
import { useMobileTrackEvents } from './useMobileTrackEvents';
import { DiscountsSection } from '../DiscountsSection';

const Mobile = ({
  addOns,
  discountedTotalPrice,
  services,
  totalPrice,
  discounts,
  totalText,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { trackEvent, basketPanelRef } = useMobileTrackEvents();

  return (
    <>
      <div className={classes.placeholder}></div>
      <div className={clsx(classes.wrapper, { expanded })}>
        <ExpansionPanel
          ref={basketPanelRef}
          classes={{ root: classes.expandWrapper }}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          square
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            classes={{
              expandIcon: classes.expandIcon,
              content: classes.expandSummaryContent,
              root: classes.expandSummary,
            }}
            IconButtonProps={{
              onClick: trackEvent,
            }}
          >
            <Typography className={classes.title} variant="h3">
              Monthly bill
            </Typography>
            <div className={classes.summaryPrice}>
              <PriceWithDiscount
                price={totalPrice}
                discountedPrice={discountedTotalPrice}
                hideDesc
              />
            </div>
          </ExpansionPanelSummary>
          <div className={classes.content}>
            <ServicesSection services={services} />
            <AddOnsSection addOns={addOns} />
            <DiscountsSection discounts={discounts} />
          </div>
          <TotalPrice
            discountedTotalPrice={discountedTotalPrice}
            totalPrice={totalPrice}
            text={totalText}
          />
        </ExpansionPanel>
      </div>
    </>
  );
};

Mobile.propTypes = basketProps;

export { Mobile };
