import { makeStyles } from '@material-ui/core/styles';

import { INPUTS_MAX_WIDTH_PX } from '../../constants';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  wrapper: {
    marginBottom: spacing(3),
  },
  radioContainer: {
    marginBottom: spacing(3),
  },
  radioGroup: {
    display: 'flex',
    maxWidth: INPUTS_MAX_WIDTH_PX,
    padding: 0,
  },
  item: {
    flexGrow: 1,
  },
  inputsContainer: {
    maxWidth: INPUTS_MAX_WIDTH_PX,
  },
  labelContainer: {
    [breakpoints.up('sm')]: {
      fontSize: 28,
      minWidth: 400,
    },
  },
}));

export { useStyles };
