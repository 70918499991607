import React from 'react';
import { Typography } from '@material-ui/core';
import { oneOfType, string, node } from 'prop-types';

import PriceWithDiscount from 'app/components/modules/Shared/PriceWithDiscount';
import { price } from 'app/constants/propTypes';

import { useStyles } from './styles';

const TotalPrice = ({ discountedTotalPrice = null, totalPrice, text }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography variant="h4" className={classes.title}>
        Total
      </Typography>
      <div className={classes.priceWrapper}>
        <PriceWithDiscount
          price={totalPrice}
          discountedPrice={discountedTotalPrice}
          hideDesc
        />
        {text && <Typography variant="body2">{text}</Typography>}
      </div>
    </div>
  );
};

TotalPrice.propTypes = {
  discountedTotalPrice: price,
  totalPrice: price.isRequired,
  text: oneOfType([string, node]),
};

export { TotalPrice };
