import React, { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { func } from 'prop-types';

import { useStyles } from './styles';
import { formErrorsPropType } from '../propTypes';

const FormErrorSnackbar = ({ onClose, errors }) => {
  const [error, setError] = useState(null);
  const { alert } = useStyles();

  useEffect(() => {
    const error = errors ? errors[0] : null;
    setError(error);
  }, [errors]);

  const handleClose = () => {
    setError(null);
    onClose(error?.id ?? '');
  };

  return (
    <Snackbar
      open={Boolean(error)}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      {error && (
        <Alert onClose={handleClose} severity="error" className={alert}>
          {error.message}
        </Alert>
      )}
    </Snackbar>
  );
};

FormErrorSnackbar.propTypes = {
  errors: formErrorsPropType,
  onClose: func.isRequired,
};

export { FormErrorSnackbar };
