import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@utilitywarehouse/colour-system';

const useStyles = makeStyles(({ typography }) => ({
  link: {
    color: colors.cyan600,
    fontWeight: typography.fontWeightMedium,
    textDecoration: 'underline',
  },
}));

export { useStyles };
