import React from 'react';
import clsx from 'classnames';
import { string, node, oneOfType } from 'prop-types';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import { basketItemProps } from '../propTypes';
import { ServiceIcon } from '../ServiceIcon';
import { productMap } from 'app/redux/modules/Bundles/constants';
import { addOnMap } from 'app/redux/modules/OrderSummary/constants';

const BodyText = ({ children, className = null, ...restProps }) => {
  const { text, bodyText } = useStyles();

  return (
    <Typography
      className={clsx(text, bodyText, className)}
      variant="body2"
      {...restProps}
    >
      {children}
    </Typography>
  );
};

const Heading = ({ children, ...restProps }) => {
  const { text, heading } = useStyles();

  return (
    <Typography className={clsx(text, heading)} variant="h4" {...restProps}>
      {children}
    </Typography>
  );
};

const Item = ({
  id,
  items = null,
  priceValue,
  oneTimeCost = null,
  hasDiscount = false,
}) => {
  const {
    wrapper,
    leftContainer,
    rightContainer,
    oneTimeCost: oneTimeCostClass,
    bolder,
    greenText,
  } = useStyles();

  const label = productMap[id] || addOnMap[id];

  if (!label) {
    // TODO: Consider handling inconsistent id property
    return null;
  }

  return (
    <li className={wrapper}>
      <div className={leftContainer}>
        <ServiceIcon serviceId={id} />
        <div>
          <Heading>{label}</Heading>
          {items?.map((item) => (
            <BodyText key={item}>{item}</BodyText>
          ))}
        </div>
      </div>
      <div className={rightContainer}>
        <Heading className={clsx({ [greenText]: hasDiscount })}>
          {priceValue}
        </Heading>
        {oneTimeCost && (
          <BodyText className={oneTimeCostClass}>
            {oneTimeCost.text}{' '}
            {oneTimeCost.value && (
              <BodyText className={bolder} component="span">
                {oneTimeCost.value}
              </BodyText>
            )}{' '}
            {oneTimeCost.subtext}
          </BodyText>
        )}
      </div>
    </li>
  );
};

const typographyPropTypes = {
  className: string,
  children: oneOfType([node, string]).isRequired,
};

BodyText.propTypes = typographyPropTypes;
Heading.propTypes = typographyPropTypes;
Item.propTypes = basketItemProps;

export { Item };
