export default ({ palette, spacing }) => ({
  bgContainer: {
    backgroundColor: palette.text.primary,
    color: palette.common.white,
    padding: spacing(0, 0, 4),
    marginTop: 'auto',
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    marginBottom: 24,
    marginTop: 24,
    width: '100%',
  },
  linksWrapper: {
    display: 'flex',
  },
  link: {
    color: palette.common.white,
    marginRight: 20,
  },
});
