import React from 'react';
import { Typography } from '@material-ui/core';
import { bool, objectOf, string, oneOfType, func } from 'prop-types';

import { convertStringToBool } from 'app/redux/utils/convertStringToBool';
import { referralAndLegalSection } from 'app/redux/modules/YourDetails/form';
import TextField from 'modules/CustomerUI/TextField';
import RadioButtonGroup from 'modules/Shared/MultiSelection/RadioButtonGroup';
import { errorsPropType } from 'app/components/modules/YourDetails/propTypes';
import { getFieldError } from 'app/components/modules/YourDetails/getFieldError';

import { YES_NO_OPTIONS } from '../../constants';
import { useStyles } from './styles';

const {
  IS_REFERRAL,
  REFERRER_NAME_FIRST,
  REFERRER_NAME_LAST,
  REFERRER_ACCOUNT,
} = referralAndLegalSection;

const ReferralForm = ({ values, pristine, errors, onFormChange }) => {
  const classes = useStyles();

  const getFieldProps = (name) => {
    const { hasError, errorMessage } = getFieldError({
      name,
      errors,
      pristine,
    });

    return {
      name,
      onChange: (_, value) => onFormChange(name, value),
      value: values[name],
      error: hasError,
      errorMessage,
    };
  };

  return (
    <div className={classes.wrapper}>
      <RadioButtonGroup
        radioGroupClasses={{
          container: classes.radioContainer,
          radioGroup: classes.radioGroup,
          radioButton: classes.item,
        }}
        label="Finally, did you find out about us from a UW Partner or customer?"
        options={YES_NO_OPTIONS}
        name={IS_REFERRAL}
        onChange={(_, value) =>
          onFormChange(IS_REFERRAL, convertStringToBool(value))
        }
        value={values[IS_REFERRAL]}
      />

      {values[IS_REFERRAL] && (
        <>
          <Typography>
            Great! Please give us their details so we can send them their
            referral bonus.
          </Typography>
          <div className={classes.inputsContainer}>
            <TextField
              type="text"
              fullWidth
              label="First name"
              {...getFieldProps(REFERRER_NAME_FIRST)}
            />
            <TextField
              type="text"
              fullWidth
              label="Last name"
              {...getFieldProps(REFERRER_NAME_LAST)}
            />
            <TextField
              type="text"
              fullWidth
              label="UW Partner number or customer account number"
              placeholder="A00000/1234567"
              textFieldClasses={{
                label: classes.labelContainer,
              }}
              {...getFieldProps(REFERRER_ACCOUNT)}
            />
          </div>
        </>
      )}
    </div>
  );
};

ReferralForm.propTypes = {
  errors: errorsPropType,
  onFormChange: func.isRequired,
  pristine: objectOf(bool),
  values: objectOf(oneOfType([string, bool])).isRequired,
};

export { ReferralForm };
