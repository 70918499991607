export default ({ palette, breakpoints, typography, ...theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexGrow: 1,

    [breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  buttons: {
    display: 'inline-block',
    width: '100%',
    paddingTop: theme.spacing(5),

    [breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  button: {
    width: '100%',
    '& + &': {
      marginTop: theme.spacing(2),
    },

    [breakpoints.up('sm')]: {
      width: 'auto',

      '& + &': {
        marginTop: 0,
        marginLeft: theme.spacing(2),
      },
    },
  },
  linksWrapper: {
    ...typography.body1,
    width: '100%',

    display: 'flex',
    flexDirection: 'column',

    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,

    [breakpoints.up('sm')]: {
      width: 'auto',
      flexDirection: 'row',
    },
  },
  linksColumn: {
    '& + &': {
      marginTop: theme.spacing(3),
    },
    [breakpoints.up('sm')]: {
      '& + &': {
        marginTop: 0,
        marginLeft: theme.spacing(4),
      },
    },
  },
  linksColumnTitle: {
    marginBottom: theme.spacing(),
  },
  link: {
    color: palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 20,
    textDecoration: 'underline',

    '& + &': {
      marginTop: theme.spacing(),
    },
  },
  mobileLink: {
    display: 'none',
    [breakpoints.down('sm')]: {
      color: palette.common.white,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      marginRight: 20,
    },
  },
  startAgain: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    [breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  startAgainLabel: {
    color: palette.common.white,
    borderBottom: `2px solid ${palette.secondary.main}`,
  },
  cbcButton: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      color: 'inherit',
      borderColor: palette.common.white,
    },
  },
  appId: {
    userSelect: 'all',
    whiteSpace: 'nowrap',
  },
});
