import React from 'react';
import { string } from 'prop-types';
import {
  ElectricityMediumIcon,
  BroadbandMediumIcon,
  MobileMediumIcon,
  InsuranceMediumIcon,
  CashbackCardMediumIcon,
  BillProtectionMediumIcon,
} from '@utilitywarehouse/react-icons';

import {
  PRODUCT_BROADBAND,
  PRODUCT_ENERGY,
  PRODUCT_INSURANCE,
  PRODUCT_MOBILE,
} from 'app/redux/modules/Bundles/constants';
import {
  ADD_ON_BILL_PROTECTOR,
  ADD_ON_CASHBACK_CARD,
} from 'app/redux/modules/OrderSummary/constants';

import { useStyles } from './styles';

const serviceIconMap = new Map([
  [PRODUCT_ENERGY, ElectricityMediumIcon],
  [PRODUCT_BROADBAND, BroadbandMediumIcon],
  [PRODUCT_MOBILE, MobileMediumIcon],
  [PRODUCT_INSURANCE, InsuranceMediumIcon],
  [ADD_ON_CASHBACK_CARD, CashbackCardMediumIcon],
  [ADD_ON_BILL_PROTECTOR, BillProtectionMediumIcon],
]);

const getServiceIcon = (id) => serviceIconMap.get(id) ?? null;

const ServiceIcon = ({ serviceId }) => {
  const classes = useStyles(serviceId)();
  const Icon = getServiceIcon(serviceId);

  if (!Icon) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <Icon className={classes.icon} />
    </div>
  );
};

ServiceIcon.propTypes = {
  serviceId: string.isRequired,
};

export { ServiceIcon };
