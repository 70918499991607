import React from 'react';
import { func } from 'prop-types';

import { Link } from '../Link';

const EeccTerms = ({ onClick }) => (
  <>
    , as well as the{' '}
    <Link onClick={onClick}>Broadband and Mobile Contract Summary</Link>
  </>
);

EeccTerms.propTypes = {
  onClick: func.isRequired,
};

export { EeccTerms };
