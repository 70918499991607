import React from 'react';
import { shape, arrayOf } from 'prop-types';

import { basketItemProps } from '../propTypes';
import { Item } from '../Item';
import { useStyles } from './styles';
import { TRY_BEFORE_YOU_BUY_PROMO } from 'app/redux/modules/Broadband/constants';
import { formatPrice } from 'app/redux/utils/formatter';

const ServicesSection = ({ services }) => {
  const classes = useStyles();

  return (
    <ul className={classes.list}>
      {services.map(({ id, items, priceValue, oneTimeCost, serviceRaw }) => {
        let additionalCost = oneTimeCost;
        let hasDiscount = false;

        // Try Before You Buy promo
        if (
          id === 'broadband' &&
          serviceRaw?.tariff?.contract?.promo?.id === TRY_BEFORE_YOU_BUY_PROMO
        ) {
          const {
            tariff: {
              contract: { length, promo },
            },
            price,
          } = serviceRaw;

          hasDiscount = true;
          additionalCost = {
            text: (
              <>
                for {promo.length} months,
                <br /> then
              </>
            ),
            value: formatPrice(price),
            subtext: (
              <>
                <br />
                for {length - promo.length} months
              </>
            ),
          };
        }

        return (
          <Item
            key={id}
            id={id}
            items={items}
            priceValue={priceValue}
            oneTimeCost={additionalCost}
            hasDiscount={hasDiscount}
          />
        );
      })}
    </ul>
  );
};

ServicesSection.propTypes = {
  services: arrayOf(shape(basketItemProps)).isRequired,
};

export { ServicesSection };
