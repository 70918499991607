import { useRef } from 'react';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';
import useScrollIntoView from 'app/lib/analytics/useScrollIntoView';

const useMobileTrackEvents = () => {
  const trackBasketDisplayed = useTrackOnce();
  const trackBasketClicked = useTrackOnce();
  const basketPanelRef = useRef();

  const trackEvent = () => {
    trackBasketClicked(EVENTS.BASKET_PANEL_EXPANDED);
  };

  useScrollIntoView(
    basketPanelRef,
    ({ visible }) => {
      if (visible) trackBasketDisplayed(EVENTS.BASKET_PANEL_DISPLAYED);
    },
    { threshold: 0.8 }
  );

  return { trackEvent, basketPanelRef };
};

export { useMobileTrackEvents };
