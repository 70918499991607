import React from 'react';
import PropTypes from 'prop-types';

import { convertStringToBool } from 'app/redux/utils/convertStringToBool';
import RadioButtonGroup from 'app/components/modules/Shared/MultiSelection/RadioButtonGroup';
import TextField from 'modules/CustomerUI/TextField';
import { aboutYouSection } from 'redux/modules/YourDetails/form';

import { YES_NO_OPTIONS } from '../../constants';
import { DobPicker } from '../DobPicker';
import useStyles from '../styles';

const {
  ABOUT_YOU_CBC_ADDITIONAL_SELECT,
  ABOUT_YOU_CBC_ADDITIONAL_NAME_FIRST,
  ABOUT_YOU_CBC_ADDITIONAL_NAME_LAST,
  ABOUT_YOU_CBC_ADDITIONAL_DATE_OF_BIRTH,

  ABOUT_YOU_ADDITIONAL_NAME_FIRST,
  ABOUT_YOU_ADDITIONAL_NAME_LAST,
  ABOUT_YOU_ADDITIONAL_DATE_OF_BIRTH,
} = aboutYouSection;

const CashbackSection = ({
  hasCashback,
  fields,
  updateForm,
  getFieldProps,
  fieldPristine,
}) => {
  const classes = useStyles();

  const onSelectAdditionCbcHolder = (_, value) => {
    updateForm(ABOUT_YOU_CBC_ADDITIONAL_SELECT, convertStringToBool(value));

    if (convertStringToBool(value) === true) {
      fieldPristine[ABOUT_YOU_CBC_ADDITIONAL_NAME_FIRST] &&
        updateForm(
          ABOUT_YOU_CBC_ADDITIONAL_NAME_FIRST,
          fields[ABOUT_YOU_ADDITIONAL_NAME_FIRST]
        );
      fieldPristine[ABOUT_YOU_CBC_ADDITIONAL_NAME_LAST] &&
        updateForm(
          ABOUT_YOU_CBC_ADDITIONAL_NAME_LAST,
          fields[ABOUT_YOU_ADDITIONAL_NAME_LAST]
        );
      fieldPristine[ABOUT_YOU_CBC_ADDITIONAL_DATE_OF_BIRTH] &&
        updateForm(
          ABOUT_YOU_CBC_ADDITIONAL_DATE_OF_BIRTH,
          fields[ABOUT_YOU_ADDITIONAL_DATE_OF_BIRTH]
        );
    }
  };

  return (
    <>
      {hasCashback && (
        <RadioButtonGroup
          radioGroupClasses={{
            container: classes.formAdditionalSelect,
            radioGroup: classes.formAdditionalSelectItems,
            radioButton: classes.formAdditionalSelectItem,
          }}
          label="Would you like to add another cardholder for your Cashback Card?"
          options={YES_NO_OPTIONS}
          onChange={onSelectAdditionCbcHolder}
          value={fields[ABOUT_YOU_CBC_ADDITIONAL_SELECT]}
          name={ABOUT_YOU_CBC_ADDITIONAL_SELECT}
        />
      )}
      {fields[ABOUT_YOU_CBC_ADDITIONAL_SELECT] && (
        <>
          <TextField
            className={classes.textInput}
            label="First name"
            {...getFieldProps(ABOUT_YOU_CBC_ADDITIONAL_NAME_FIRST)}
          />
          <TextField
            className={classes.textInput}
            label="Last name"
            {...getFieldProps(ABOUT_YOU_CBC_ADDITIONAL_NAME_LAST)}
          />
          <DobPicker
            className={classes.textInput}
            {...getFieldProps(ABOUT_YOU_CBC_ADDITIONAL_DATE_OF_BIRTH)}
          />
        </>
      )}
    </>
  );
};

CashbackSection.propTypes = {
  hasCashback: PropTypes.bool.isRequired,
  fields: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  updateForm: PropTypes.func.isRequired,
  getFieldProps: PropTypes.func.isRequired,
  fieldPristine: PropTypes.objectOf(PropTypes.bool),
};

export { CashbackSection };
