import React from 'react';
import { bool, func, string, object, objectOf, oneOfType } from 'prop-types';

import { convertStringToBool } from 'app/redux/utils/convertStringToBool';
import { directDebitSection } from 'redux/modules/YourDetails/form';
import { BillingAddressPickerContainer } from 'app/redux/modules/YourDetails/containers/BillingAddressPickerContainer';
import RadioButtonGroup from 'modules/Shared/MultiSelection/RadioButtonGroup';
import { getFieldError } from 'app/components/modules/YourDetails/getFieldError';
import { errorsPropType } from 'app/components/modules/YourDetails/propTypes';
import { useStyles } from './styles';
import { YES_NO_OPTIONS } from '../../constants';

const { BILLING_ADDRESS, BILLING_ADDRESS_SAME } = directDebitSection;

const BillingAddress = ({
  values,
  pristine,
  errors,
  onFormChange,
  supplyAddress,
}) => {
  const classes = useStyles();

  const getFieldProps = (name) => {
    const { hasError, errorMessage } = getFieldError({
      name,
      errors,
      pristine,
    });

    return {
      name,
      value: values[name],
      error: hasError,
      errorMessage,
    };
  };

  const getSameBillingAddressLabel = (address) => {
    return `Is your billing address also ${address.firstLine}, ${address.postcode}?`;
  };

  return (
    <div className={classes.wrapper}>
      <RadioButtonGroup
        radioGroupClasses={{
          container: classes.radioContainer,
          radioGroup: classes.radioGroup,
          radioButton: classes.item,
        }}
        label={getSameBillingAddressLabel(supplyAddress)}
        options={YES_NO_OPTIONS}
        name={BILLING_ADDRESS_SAME}
        onChange={(_, value) =>
          onFormChange(BILLING_ADDRESS_SAME, convertStringToBool(value))
        }
        value={values[BILLING_ADDRESS_SAME]}
      />
      {!values[BILLING_ADDRESS_SAME] && (
        <div className={classes.inputsContainer}>
          <BillingAddressPickerContainer
            onSelectAddress={(value) => onFormChange(BILLING_ADDRESS, value)}
            postcodeLabel="Your billing postcode"
            resultsLabel="Your billing address"
            {...getFieldProps(BILLING_ADDRESS)}
          />
        </div>
      )}
    </div>
  );
};

BillingAddress.propTypes = {
  values: objectOf(oneOfType([string, bool])).isRequired,
  pristine: objectOf(bool),
  errors: errorsPropType,
  onFormChange: func.isRequired,
  supplyAddress: object.isRequired,
};

export { BillingAddress };
