import { getSpacing } from 'app/lib/theme';
import { colors } from '@utilitywarehouse/colour-system';

export default (theme) => {
  const backgroundColorAlternative = colors.grey50;
  const spacing = getSpacing(theme);

  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    container: {
      maxWidth: `calc(1240px + ${spacing(6)}px)`,
      padding: spacing(0, 1),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        padding: spacing(0, 3),
      },
    },
    alternate: {
      background: backgroundColorAlternative,
    },
    newRoot: {
      backgroundColor: theme.palette.background.default,
    },
    white: {
      backgroundColor: theme.palette.common.white,
    },
    newContainer: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
    },
  };
};
