import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  wrapper: {
    marginBottom: spacing(3),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1),
  },
  debitGuaranteeList: {
    listStyle: 'none',
    margin: spacing(2, 0, 0),
    padding: 0,
  },
  debitGuaranteeListItem: {
    display: 'inline-flex',
    columnGap: spacing(2),
    '& + &': {
      marginTop: spacing(1),
    },
  },
  debitGuaranteeListIcon: {
    color: palette.success.main,
  },
  contentDropdownButton: {
    marginLeft: spacing(-1),
  },
  guaranteeLabel: {
    [breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
}));

export { useStyles };
