import { makeStyles } from '@material-ui/core/styles';

import { INPUTS_MAX_WIDTH_PX } from '../../constants';

const useStyles = makeStyles(() => ({
  inputsContainer: {
    maxWidth: INPUTS_MAX_WIDTH_PX,
  },
}));

export { useStyles };
